import * as React from 'react';
import { Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { Row, Col } from 'antd/es/grid';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useTranslation } from 'react-i18next';
import SEO from 'components/Seo';
import { getTranslates } from 'helpers/translate.helper';

export default () => {
  const {
    i18n: { language }
  } = useTranslation();

  const data = useStaticQuery(query);
  const imagesBalloon = [
    data.balloonMobile.childImageSharp.fluid,
    {
      ...data.balloonDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`
    }
  ];

  return (
    <Container>
      <SEO title={getTranslates(data.allStrapiLesLandCommon, 'header__philosophy', language)} />

      <Row>
        <Col sm={12}>
          <Typography.Title className="clp-typography-title clp-typography-title_size_md mb-25 mt-30">
            {getTranslates(data.allStrapiLesLandPhilosophy, 'header', language)}
          </Typography.Title>

          <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md">
            {getTranslates(data.allStrapiLesLandPhilosophy, 'par1', language)}
          </Typography.Paragraph>

          <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md">
            {getTranslates(data.allStrapiLesLandPhilosophy, 'par2', language)}
          </Typography.Paragraph>

          <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md">
            {getTranslates(data.allStrapiLesLandPhilosophy, 'par3', language)}
          </Typography.Paragraph>

          <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md">
            {getTranslates(data.allStrapiLesLandPhilosophy, 'par4', language)}
          </Typography.Paragraph>

          <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md">
            {getTranslates(data.allStrapiLesLandPhilosophy, 'par5', language)}
          </Typography.Paragraph>

          <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md">
            {getTranslates(data.allStrapiLesLandPhilosophy, 'par6', language)}
          </Typography.Paragraph>

          <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md">
            {getTranslates(data.allStrapiLesLandPhilosophy, 'par7', language)}
          </Typography.Paragraph>

          <Typography.Text className="clp-typography-text clp-typography-text_size_md font_family_merriweather font_color_primary font_style_italic">
            Les Embellisseurs
          </Typography.Text>
        </Col>

        <Col sm={12}>
          <Img fluid={imagesBalloon} alt="hot air balloon" className="m_center_x" imgStyle={{ objectFit: 'contain' }} />
        </Col>
      </Row>
    </Container>
  );
};

export const query = graphql`
  query {
    balloonMobile: file(relativePath: { eq: "images/hotAirBalloonDesk.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 170, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFluid_tracedSVG
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    balloonDesktop: file(relativePath: { eq: "images/hotAirBalloonDesk.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 560, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFluid_tracedSVG
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    allStrapiLesLandCommon {
      edges {
        node {
          header__philosophy__en
          header__philosophy__fr
        }
      }
    }
    allStrapiLesLandPhilosophy {
      edges {
        node {
          header__en
          header__fr
          par1__en
          par2__en
          par3__en
          par4__en
          par5__en
          par6__en
          par7__en
          par1__fr
          par2__fr
          par3__fr
          par4__fr
          par5__fr
          par6__fr
          par7__fr
        }
      }
    }
  }
`;
